// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import PremimImg from 'assets/images/premium-quality.png'

import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Skelton from 'components/commonComponents/Skelton';
import Button from 'components/commonComponents/Button';
import { Fab, ToggleButton, ToggleButtonGroup } from '@mui/material';


import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapIcon from '@mui/icons-material/Map';
import SearchIcon from '@mui/icons-material/Search';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoModal from 'components/modals/InfoModal';
import SubmitEntry from 'components/modals/SubmitAwardEntryModal';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ChatModel from './chatModal';

import AiButton from 'assets/images/AiButton.png'

const Awards = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [awards, setData] = useState([]);
    const [type, setAlignment] = useState('year');
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)
    const [info, setInfo] = useState({ title: 'info', description: '' })
    const [showCondition, setShowCondition] = useState(false)
    const [condition, setCondition] = useState({ title: 'info', description: '' })
    const [showSubmitform, setShowSubmitform] = useState(false)
    const [isAuthor, setAuthor] = useState(false)
    const [showChat, setShowChat] = useState(false)

    const handleChange = (event, newType) => {
        if (newType !== null) {
            setAlignment(newType);
            fetchAwards(newType)
        }
    };

    const fetchAwards = async (type = 'year') => {
        setLoading(true)
        const res = await fetchData(restAPIs.awardsByType(currentLang?.id, type));
        setData(res.awards)
        setLoading(false);
    }
    useEffect(() => {
        fetchAwards();
        fetchInfo();
    }, [currentLang]);

    const handleOnClickAward = (typeId) => {
        navigate(`/awards/list/${type}/${typeId}`)
    }

    const fetchInfo = async () => {
        const res = await fetchData(restAPIs.getEVPages(currentLang?.id, 57));
        setInfo({ title: res.page.name, description: res.page.description })
        const res2 = await fetchData(restAPIs.getEVPages(currentLang?.id, 56));
        setCondition({ title: res2.page.name, description: res2.page.description })
    }

    const handleCloseModelInfo = () => {
        setShowInfo(false)
    }

    const handleCloseModelCond = () => {
        setShowCondition(false)
    }

    const handleOpenModelInfo = () => {
        setShowInfo(true)
    }

    const handleOpenModelCond = () => {
        setShowCondition(true)
    }

    const handleCloseModelSubmit = () => {
        setShowSubmitform(false)
    }

    const handleBook = (bookId) => {
        navigate(`/book/${bookId}`)
    }
    const handleMap = () => {
        navigate(`/book/map`)
    }

    const toggleImg = () => {
        let isauth = isAuthor
        setAuthor(!isauth)
    }

    const handleOpenAsk = () => {
        setShowChat(true)
    }

    return (
        <>
            <Banner
                title={t('ib_awards')}
                // decription='awards'
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>

                <div className='d-flex justify-content-between mb-3'>
                    <h1 className='__common_page_head'>{t('ib_awards')}</h1>
                    <div className='d-flex align-items-center'>
                        <div>
                            <Fab style={{ color: '#0284c5', margin: '0 5px', cursor: 'pointer' }} title={isAuthor ? 'Switch to see book images' : 'Switch to see author images'} size='small' aria-label="Map" onClick={() => toggleImg()}>
                                <CameraswitchIcon />
                            </Fab>
                            <Fab style={{ backgroundColor: '#0284c5', color: '#fff', margin: '0 5px', cursor: 'pointer' }} title='Map' size='small' aria-label="Map" onClick={() => handleMap()}>
                                <MapIcon />
                            </Fab>
                            <Fab style={{ color: '#0284c5', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Search" >
                                <SearchIcon />
                            </Fab>
                            <Fab title={info?.title} style={{ backgroundColor: '#0284c5', color: '#fff', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Info" onClick={() => handleOpenModelInfo()}>
                                <InfoOutlinedIcon />
                            </Fab>
                            <Fab title={condition?.title} style={{ color: '#0284c5', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Conditions" onClick={() => handleOpenModelCond()}>
                                <LightbulbIcon />
                            </Fab>
                            <Fab style={{ backgroundColor: '#0284c5', color: '#fff', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Submit" onClick={() => setShowSubmitform(true)}>
                                <FileUploadIcon />
                            </Fab>
                            <Fab style={{ color: '#0284c5', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Search" onClick={() => setShowChat(true)}>
                                <img src={AiButton} style={{ width: '20px' }} />
                            </Fab>
                        </div>

                        <div className='d-flex align-items-center'>
                            <h6 style={{ margin: '0 10px' }}>Filter</h6>
                            <ToggleButtonGroup
                                color="primary"
                                value={type}
                                exclusive
                                onChange={handleChange}
                            >
                                <ToggleButton value="year">By Year</ToggleButton>
                                <ToggleButton value="categoryId">By Category</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>


                {isLoading ?
                    <div className='__awards_list'>
                        {[...Array(5)].map((item, idx) => {
                            return (
                                <Skelton key={idx} />
                            )
                        })}
                    </div>

                    :

                    awards && awards.map((award, idx) => {
                        return (
                            <div className='__single_set mb-5' key={idx}>
                                <div className='d-flex justify-content-between mb-3'>

                                    <h1 className='__sec_hd'>{award.typeName}</h1>
                                    <Button
                                        label={t('see_all')}
                                        style={{ margin: 0 }}
                                        handleOnClick={() => handleOnClickAward(award.typeId)}
                                    />
                                </div>
                                <div className='__awards_list'>
                                    {
                                        award?.winners?.map((item, i) => {
                                            return (
                                                <a className="singleBook" key={i} onClick={() => handleBook(item.bookId)}>
                                                    <div style={{ position: 'relative', background: '#0001' }}>
                                                        {isAuthor ? <img src={item.authorImage} /> : <img src={item.coverImage} />}
                                                        <div className="bookBind"></div>
                                                        <span className="subJect">{item.categoryName}</span>
                                                        {item?.winner === 1 && <img src={PremimImg} alt="" className="__premim_qlit" />}
                                                    </div>
                                                    <div className="singleBookInner">
                                                        <h1>{item.bookName}</h1>
                                                        <h5>{item.authorName}</h5>
                                                        <h6>{item?.year}</h6>
                                                    </div>
                                                </a>
                                            )
                                        })
                                    }
                                    <div className='__dummy' onClick={() => handleOnClickAward(award.typeId)}>
                                        {t('see_all')}
                                    </div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                </div>
                            </div>
                        )
                    }
                    )
                }

                <InfoModal
                    open={showInfo}
                    onClose={handleCloseModelInfo}
                    title={info.title}
                    description={info.description}
                />

                <InfoModal
                    open={showCondition}
                    onClose={handleCloseModelCond}
                    title={condition.title}
                    description={condition.description}
                />
                <SubmitEntry open={showSubmitform} onClose={handleCloseModelSubmit} />

                <ChatModel
                    open={showChat}
                    onClose={() => { setShowChat(false) }}
                />

                <Download />
            </div>
        </>
    );
};

export default Awards;
